import router from '../router';

class AuthService {
  login(user) {
    // this.SET_TOKEN(jwt);
    // this.SET_USERDATA(userData);
    localStorage.setItem('user', JSON.stringify(user));
    router.push('/dashboard');
  }
  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();
