<template>
  <div />
</template>
<script>
import AuthService from '../services/auth.service';
import cookies from 'vue-cookies';
import VueJwtDecode from 'vue-jwt-decode';
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations(['SET_USERDATA', 'SET_TOKEN']),
  },
  mounted() {
    var jwt = cookies.get('jwt');
    if (!jwt) {
      this.$router.push('/');
    }

    const decodedJwt = VueJwtDecode.decode(jwt);
    const userData = decodedJwt.data;

    AuthService.login(userData);
  },
};
</script>
