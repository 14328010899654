<template>
  <div>
    <div>
      <img
        class="m-3"
        style="width: 700px"
        :src="require('../assets/slb-logo.jpg')"
      />
    </div>
    <div>
      <Button @click="doLogin" class="ma-2 primary" label="Please Login" />
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import Button from 'primevue/button';

export default {
  components: {
    Button,
  },
  methods: {
    doLogin() {
      window.location.href = this.apiBaseUrl + '/api/auth/google';
    },
    ...mapMutations(['SET_APIBASEURL']),
  },
  computed: {
    ...mapState(['apiBaseUrl']),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    this.SET_APIBASEURL(process.env.VUE_APP_API_BASE_URL);
  },
  created() {
    console.log('is logged in:' + JSON.stringify(this.$store.state.auth));
    if (this.loggedIn) {
      this.$router.push('/dashboard');
    }
  },
};
</script>
