<template>
  <div
    class="flex flex-column"
    style="align-items: center; justify-content: center"
  >
    <div class="container">
      <div class="family-header">
        <h1>Volunteer Management</h1>
      </div>
      <div class="add-family m-4">
        <div>{{ message }}</div>
        <Button class="p-button-primary" label="Add Volunteer" @click="add" />
      </div>
    </div>
    <div class="container">
      <DataTable
        :value="volunteers"
        responsiveLayout="scroll"
        style="width: 800px"
        stripedRows
      >
        <Column field="firstName" header="First Name"></Column>
        <Column field="lastName" header="Last Name"></Column>
        <Column field="number" header="Phone Number"></Column>
        <Column field="email" header="Email"></Column>
        <Column>
          <template #body="slotProps">
            <Button
              class="context-button m-2"
              label="Edit"
              @click="edit(slotProps.data)"
            />
            <Button
              class="m-2"
              label="Delete"
              @click="remove(slotProps.data.id)"
            /> </template
        ></Column>
      </DataTable>
    </div>
    <PersonDialog
      :visible="displayPersonDialog"
      :passedPerson="volunteerToEdit"
      @update:personToEdit="updateVolunteerToEdit"
      @update:visible="closePersonDialog"
      @save="save"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import PersonDialog from '../components/PersonDialog.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import '../assets/families.css';

export default {
  components: {
    PersonDialog,
    DataTable,
    Column,
    Button,
  },
  data() {
    return {
      error: '',
      message: '',
      displayPersonDialog: false,
      volunteerToEdit: null,
    };
  },

  methods: {
    add() {
      this.volunteerToEdit = {
        firstName: '',
        lastName: '',
        number: '',
        email: '',
      };
      this.displayPersonDialog = true;
    },
    edit(volunteer) {
      this.volunteerToEdit = JSON.parse(JSON.stringify(volunteer));
      this.displayPersonDialog = true;
    },
    remove(volunteerId) {
      axios
        .delete('/api/volunteers/' + volunteerId)
        .then((response) => {
          this.message = response.data.message;
          this.$store.dispatch('loadVolunteers');
        })
        .catch((err) => {
          this.err =
            err.response.status +
            ' failure from /api/volunteers/:volunteerId PUT';
        });
    },
    save(volunteer) {
      if (volunteer.id === undefined) {
        this.create(volunteer);
      } else {
        this.update(volunteer);
      }
    },
    create(volunteer) {
      axios
        .post('/api/volunteers', volunteer)
        .then((response) => {
          this.message = response.data.message;
          this.displayPersonDialog = false;
          this.$store.dispatch('loadVolunteers');
        })
        .catch((err) => {
          this.err = err.response.status + ' failure from /api/volunteers POST';
        });
    },
    update(volunteer) {
      axios
        .put('/api/volunteers/' + volunteer.id, volunteer)
        .then((response) => {
          this.message = response.data.message;
          this.displayPersonDialog = false;
          this.$store.dispatch('loadVolunteers');
        })
        .catch((err) => {
          this.err =
            err.response.status +
            ' failure from /api/volunteers/:volunteerId PUT';
        });
    },
    updateVolunteerToEdit(volunteer) {
      this.volunteerToEdit = volunteer;
    },
    closePersonDialog() {
      this.displayPersonDialog = false;
    },
  },
  computed: {
    ...mapState(['volunteers']),
  },
  mounted() {
    this.$store.dispatch('loadVolunteers');
  },
};
</script>
