<template>
  <div
    style="
      padding-top: 1rem;
      align-items: center;
      justify-content: center;
      display: flex;
    "
  >
    <div class="flex flex-column">
      <div class="flex"></div>
      <div class="flex">
        <Card style="background-color: #cecece">
          <template #title> Send Broadcast Message </template>
          <template #content>
            <div class="mt-3 mb-3">
              Choose parties to receive broadcast message. This will send the
              specified message to all selected families below.
            </div>

            <div class="flex flex-row align-items-center mb-4">
              <label class="mr-4">Selected Group</label>
              <Dropdown
                :options="groups"
                optionLabel="name"
                v-model="selectedGroup"
                placeholder="Choose One"
                @change="updatePeople"
              />
            </div>

            <div class="flex justify-content-between pb-2">
              <label>Message to send</label>
              <div class="flex flex-row">
                {{ this.charCount }}/1600 characters
              </div>
            </div>
            <div class="flex flex-column">
              <Textarea
                rows="7"
                cols="50"
                v-model="message"
                @keydown="updateCharCount"
              />
            </div>
            <div class="flex flex-column">
              <Message
                v-for="msg of messages"
                :severity="msg.severity"
                :life="msg.life"
                :sticky="false"
                :key="msg.key"
                >{{ msg.content }}</Message
              >
            </div>
            <div class="grid mt-4">
              <div class="col-8">
                <Button
                  :label="selectAllText"
                  @click="selectAll"
                  class="p-button-secondary"
                />
                <ul style="list-style-type: none">
                  <li class="m-1" v-for="p in people" :key="p.number">
                    <Checkbox :value="p.number" v-model="selectedPeople" />
                    {{ `${p.firstName} ${p.lastName}` }}
                  </li>
                </ul>
              </div>
              <div class="col-4" style="text-align: right">
                <Button
                  :disabled="maxLengthExceeded"
                  @click="sendBroadcastMessage"
                  class="p-button-primary"
                  >Send Message</Button
                >
              </div>
            </div>

            <div class="m-2 mt-4">
              <div :class="{ red: maxLengthExceeded }" class="ma-5"></div>
              <div class="ma-5" :hidden="peopleSelected">
                {{ this.validationMessage }}
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapState } from 'vuex';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';

export default {
  components: {
    Card,
    Button,
    Textarea,
    Dropdown,
    Checkbox,
    Message,
  },
  data() {
    return {
      dialog: false,
      groups: [
        { name: 'Families', value: 'families' },
        { name: 'Volunteers', value: 'volunteers' },
      ],
      selectedGroup: null,
      people: [],
      selectedPeople: [],
      allSelected: false,
      messages: [],
      message: '',
      charCount: 0,
      validationMessage: '',
      selectAllText: 'Select All',
      broadcastSent: false,
    };
  },
  methods: {
    selectAll() {
      this.selectedPeople = [];
      if (!this.allSelected) {
        this.people.forEach((f) => {
          this.selectedPeople.push(f.number);
        });
        this.selectAllText = 'Deselect All';
      } else {
        this.selectAllText = 'Select All';
      }
      this.allSelected = !this.allSelected;
    },
    updatePeople() {
      if (this.selectedGroup.value === 'volunteers') {
        this.selectedPeople = [];
        this.people = this.volunteers;
      } else if (this.selectedGroup.value === 'families') {
        this.selectedPeople = [];
        this.people = this.families;
      } else {
        this.selectedPeople = [];
        this.people = [];
      }
    },
    updateCharCount() {
      this.charCount = this.message.length;
    },
    sendBroadcastMessage() {
      if (this.selectedPeople.length == 0) {
        this.validationMessage =
          'Must select at least one family for broadcast message';
        return;
      }

      const broadcastPayload = {
        message: this.message,
        numbers: this.selectedPeople,
      };

      axios
        .post('/api/messages/broadcast', broadcastPayload)
        .then(() => {
          this.messages.push({
            life: 5000,
            severity: 'success',
            content: 'Broadcast Successfully Sent',
            key: Math.ceil(Math.random() * 1000000),
          });
          this.selectedPeople = [];
        })
        .catch((err) => {
          this.messages.push({
            life: 5000,
            severity: 'error',
            content:
              'Broadcast not successfully sent. Response:' +
              err.response.status,
            key: Math.ceil(Math.random() * 1000000),
          });
        });
    },
  },
  computed: {
    ...mapState(['families', 'volunteers']),
    maxLengthExceeded() {
      return this.charCount > 1600;
    },
    peopleSelected() {
      return this.selectedPeople.length != 0;
    },
  },
  mounted() {
    this.$store.dispatch('loadFamilies');
    this.$store.dispatch('loadVolunteers');
  },
};
</script>
