import { createRouter, createWebHashHistory } from 'vue-router';

import LoggedInLayout from '../components/LoggedInLayout.vue';
import Landing from '../views/Landing.vue';
import Families from '../views/Families.vue';
import Volunteers from '../views/Volunteers.vue';
import Dashboard from '../views/Dashboard.vue';
import Broadcast from '../views/Broadcast.vue';
import LoginSuccess from '../components/LoginSuccess.vue';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      name: 'landing',
      path: '/',
      component: Landing,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/loginsuccess',
      component: LoginSuccess,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '',
      component: LoggedInLayout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: 'dashboard',
          path: '/dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'families',
          path: '/families',
          component: Families,
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'volunteers',
          path: '/volunteers',
          component: Volunteers,
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'broadcast',
          path: '/broadcast',
          component: Broadcast,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !loggedIn) {
    next({ name: 'landing' });
  } else if (!requiresAuth && loggedIn) {
    next('/dashboard');
  } else {
    next();
  }
});

export default router;
