<template>
  <div class="flex flex-row justify-content-between">
    <img style="width: 150px" :src="require('../assets/slb-logo-sm.png')" />
    <Menubar :model="items">
      <template #item="{ item }">
        <Button
          v-if="item.command"
          class="m-2 p-button-secondary"
          @click="item.command"
          >{{ item.label }}</Button
        >
        <router-link v-if="item.to" :to="item.to" v-slot="{ navigate }" custom>
          <Button class="m-2" @click="navigate">{{ item.label }}</Button>
        </router-link>
      </template>
    </Menubar>
  </div>
</template>

<script>
import Menubar from 'primevue/menubar';
import Button from 'primevue/button';

export default {
  components: {
    Menubar,
    Button,
  },
  data() {
    return {
      items: [
        {
          label: 'Volunteers',
          icon: 'pi pi-fw pi-video',
          to: 'volunteers',
        },
        {
          label: 'Families',
          icon: 'pi pi-fw pi-video',
          to: 'families',
        },
        {
          label: 'Broadcast',
          icon: 'pi pi-fw pi-video',
          to: 'broadcast',
        },
        {
          label: 'Dashboard',
          icon: 'pi pi-fw pi-video',
          to: 'dashboard',
        },
        {
          label: 'Logout',
          icon: 'pi pi-account',
          command: () => this.doLogout(),
        },
      ],
    };
  },
  methods: {
    doLogout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    },
  },
};
</script>
