<template>
  <div class="container">
    <Navigation />
    <div className="content-container">
      <router-view />
    </div>
  </div>
</template>
<script>
import Navigation from './Navigation.vue';

export default {
  components: {
    Navigation,
  },
};
</script>
