import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import PrimeVue from 'primevue/config';
import { loadFonts } from './plugins/webfontloader';
import axios from 'axios';
import setupInterceptors from './services/interceptors';

loadFonts();

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

setupInterceptors(store, router);

const app = createApp(App);
app.use(router);
app.use(store);
app.use(PrimeVue);
app.mount('#app');
