<template>
  <div class="chat-items">
    <ul>
      <li
        v-for="c in convos"
        :key="c.id"
        class="overflow-y-auto"
        :class="{ 'chat-selected': isSelected(c.id) }"
        v-on:click="selectConvo(c)"
      >
        <div class="flex flex-row justify-content-between">
          <h3>{{ c.name }}</h3>
          <div>{{ formatDate(c) }}</div>
        </div>
        <div class="mt-2 flex flex-row">{{ c.messages.length }} messages</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ConversationList',
  methods: {
    selectConvo(c) {
      this.$store.dispatch('selectConvo', c);
    },
    isSelected(id) {
      return id == this.$store.getters.selectedConvo.id;
    },
    formatDate(chat) {
      return (
        new Date(chat.last_message).toLocaleDateString('en-US') +
        ' ' +
        new Date(chat.last_message).toLocaleTimeString('en-US')
      );
    },
  },
  computed: {
    ...mapState(['convos', 'families']),
  },
  mounted() {
    this.$store.dispatch('loadFamilies');
    this.$store.dispatch('loadConvos');
  },
};
</script>
