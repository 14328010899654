<template>
  <div
    class="flex flex-column"
    style="align-items: center; justify-content: center"
  >
    <div class="container">
      <div class="family-header">
        <h1>Family Management</h1>
      </div>
      <div class="add-family m-4">
        <div>{{ message }}</div>
        <Button class="p-button-primary" label="Add Family" @click="add" />
      </div>
    </div>
    <div class="container">
      <DataTable
        :value="families"
        responsiveLayout="scroll"
        style="width: 800px"
        stripedRows
      >
        <Column field="firstName" header="First Name"></Column>
        <Column field="lastName" header="Last Name"></Column>
        <Column field="number" header="Phone Number"></Column>
        <Column field="email" header="Email"></Column>
        <Column>
          <template #body="slotProps">
            <Button
              class="context-button m-2"
              label="Edit"
              @click="edit(slotProps.data)"
            />
            <Button
              class="m-2"
              label="Delete"
              @click="remove(slotProps.data.id)"
            /> </template
        ></Column>
      </DataTable>
    </div>
    <PersonDialog
      :visible="displayPersonDialog"
      :passedPerson="familyToEdit"
      @update:personToEdit="updateFamilyToEdit"
      @update:visible="closePersonDialog"
      @save="save"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import PersonDialog from '../components/PersonDialog.vue';
import '../assets/families.css';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
  components: {
    PersonDialog,
    DataTable,
    Column,
    Button,
  },
  data() {
    return {
      error: '',
      message: '',
      displayPersonDialog: false,
      familyToEdit: null,
    };
  },
  methods: {
    add() {
      this.familyToEdit = {
        firstName: '',
        lastName: '',
        number: '',
        email: '',
      };
      this.displayPersonDialog = true;
    },
    edit(family) {
      this.familyToEdit = JSON.parse(JSON.stringify(family));
      this.displayPersonDialog = true;
    },
    remove(familyId) {
      axios
        .delete('/api/families/' + familyId)
        .then((response) => {
          this.message = response.data.message;
          this.$store.dispatch('loadFamilies');
        })
        .catch((err) => {
          this.err =
            err.response.status + ' failure from /api/families/:familyId PUT';
        });
    },
    save(family) {
      if (family.id === undefined) {
        this.create(family);
      } else {
        this.update(family);
      }
    },
    create(family) {
      axios
        .post('/api/families', family)
        .then((response) => {
          this.message = response.data.message;
          this.displayPersonDialog = false;
          this.$store.dispatch('loadFamilies');
        })
        .catch((err) => {
          this.err =
            err.response.status + ' failure from /api/families/:familyId POST';
        });
    },
    update(family) {
      axios
        .put('/api/families/' + family.id, family)
        .then((response) => {
          this.message = response.data.message;
          this.displayPersonDialog = false;
          this.$store.dispatch('loadFamilies');
        })
        .catch((err) => {
          this.err =
            err.response.status + ' failure from /api/families/:familyId PUT';
        });
    },
    updateFamilyToEdit(family) {
      this.familyToEdit = family;
    },
    closePersonDialog() {
      this.displayPersonDialog = false;
    },
  },
  computed: {
    ...mapState(['families']),
  },
  mounted() {
    this.$store.dispatch('loadFamilies');
  },
};
</script>
