<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import '/node_modules/primeflex/primeflex.css';
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import './assets/vendor/theme.scss';
import './assets/chat.css';

export default {
  name: 'App',
};
</script>
