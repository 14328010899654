<template>
  <div>
    <div class="dashboard">
      <div class="sidebar">
        <ConversationList />
      </div>
      <div class="content-container">
        <Conversation />
      </div>
    </div>
  </div>
</template>
<script>
import ConversationList from '../components/ConversationList.vue';
import Conversation from '../components/Conversation.vue';

export default {
  data() {
    return {
      error: '',
    };
  },
  methods: {},
  components: {
    ConversationList,
    Conversation,
  },
};
</script>
