import axios from 'axios';

const setup = (store, router) => {
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.originalConfig;
      if (originalConfig !== 'api/auth/google' && err.response) {
        // Token expired
        if (err.response.status === 401 || err.response.status === 403) {
          try {
            store.dispatch('auth/logout');
            router.push('/');
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
    }
  );
};

export default setup;
