<template>
  <Dialog header="Add Person" v-model="show" :style="{ width: '25vw' }">
    <div class="flex flex-column">
      <div class="flex justify-content-between pb-2">
        <label class="pt-2">First Name</label>
      </div>
      <div class="flex flex-column">
        <InputText v-model="this.person.firstName" />
      </div>
    </div>
    <div class="flex flex-column">
      <div class="flex justify-content-between pb-2">
        <label class="pt-2">Last Name</label>
      </div>
      <div class="flex flex-column">
        <InputText v-model="this.person.lastName" />
      </div>
    </div>
    <div class="flex flex-column">
      <div class="flex justify-content-between pb-2">
        <label class="pt-2">Phone Number</label>
      </div>
      <div class="flex flex-column">
        <InputText v-model="this.person.number" />
      </div>
    </div>
    <div class="flex flex-column">
      <div class="flex justify-content-between pb-2">
        <label class="pt-2">Email</label>
      </div>
      <div class="flex flex-column">
        <InputText v-model="this.person.email" />
      </div>
    </div>

    <template #footer>
      <Button
        label="Cancel"
        class="p-button-secondary m-2 justify-content-right"
        @click.stop="show = false"
      />
      <Button label="Save" class="p-button-primary m-2" @click.stop="save" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default {
  name: 'PersonDialog',
  components: {
    Dialog,
    Button,
    InputText,
  },
  props: {
    value: Boolean,
    passedPerson: Object,
    title: String,
  },
  methods: {
    save() {
      this.$emit('save', this.person);
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:visible', value);
      },
    },
    person: {
      get() {
        return this.passedPerson;
      },
      set(value) {
        this.$emit('update:personToEdit', value);
      },
    },
  },
};
</script>
