import { createStore } from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import { auth } from './auth.module';

function createEmptyConvo() {
  return {
    id: -1,
    messages: [],
  };
}

export const store = createStore({
  modules: {
    auth,
  },
  strict: true,
  plugins: [createPersistedState()],
  state: {
    convos: [],
    selectedConvo: createEmptyConvo(),
    families: [],
    volunteers: [],
    nextId: 1,
    userData: null,
    token: null,
    apiBaseUrl: null,
  },
  mutations: {
    ADD_CONVO(state, convo) {
      state.convos.push(convo);
    },
    SELECT_CONVO(state, convo) {
      state.selectedConvo = convo;
    },
    ADD_MESSAGE(state, message) {
      state.selectedConvo.messages.push(message);
    },
    SET_CONVOS(state, convos) {
      state.convos = convos;
    },
    SET_FAMILIES(state, families) {
      state.families = families;
    },
    SET_VOLUNTEERS(state, volunteers) {
      state.volunteers = volunteers;
    },
    SET_USERDATA(state, userData) {
      state.userData = userData;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_APIBASEURL(state, url) {
      state.apiBaseUrl = url;
    },
  },
  actions: {
    selectConvo(context, convo) {
      context.commit('SELECT_CONVO', convo);
    },
    addMessage(context, message) {
      context.commit('ADD_MESSAGE', message);
    },
    loadFamilies({ commit }) {
      axios
        .get('/api/families')
        .then((response) => {
          commit('SET_FAMILIES', response.data);
        })
        .catch((err) => {
          this.err = err.response.status + ' failure from /api/families';
        });
    },
    loadVolunteers({ commit }) {
      axios
        .get('/api/volunteers')
        .then((response) => {
          commit('SET_VOLUNTEERS', response.data);
        })
        .catch((err) => {
          this.err = err.response.status + ' failure from /api/volunteers';
        });
    },
    loadConvos({ commit }) {
      axios
        .get('/api/messages')
        .then((response) => {
          commit('SET_CONVOS', response.data);
        })
        .catch((err) => {
          this.error = err.response.status + ' response from /api/messages';
        });
    },
  },
  getters: {
    families(state) {
      return state.families;
    },
    volunteers(state) {
      return state.volunteers;
    },
    convos(state) {
      return state.convos;
    },
    selectedConvo(state) {
      return state.selectedConvo;
    },
    isLoggedIn(state) {
      return !!state.token && !!state.userData;
    },
    userData(state) {
      return state.userData;
    },
    token(state) {
      return state.token;
    },
    apiBaseUrl(state) {
      return state.apiBaseUrl;
    },
  },
});

export default store;
